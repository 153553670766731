import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorAppointment } from "../models/DoctorAppointment";
import { Subject } from "rxjs";

const refreshAptScheduleSubject = new Subject<boolean>();

export default {
  refreshAptSchedule: (refresh: boolean) => {
    //console.log("refreshAptSchedule", refresh);

    refreshAptScheduleSubject.next(refresh);
  },
  getAptScheduleRefresh: () => {
    return refreshAptScheduleSubject.asObservable();
  },

  insert(appoit: IDoctorAppointment) {
    return AuthAxios.post(ApiURIs.DrAppointmentInsert, JSON.stringify(appoit));
  },
  insertDr(appoit: IDoctorAppointment) {
    return AuthAxios.post(ApiURIs.DrAppointmentInsertAdmin, JSON.stringify(appoit));
  },
  varify(appoit: IDoctorAppointment) {
    return AuthAxios.post(ApiURIs.DrAppointmentVerification, JSON.stringify(appoit));
  },
  reSendCode(appointmentId: number) {
    return AuthAxios.get(ApiURIs.DrAppointmentReSendCode + "/" + appointmentId);
  },
  // approvedUnApproved(appId: number, isApp: boolean) {
  //   return AuthAxios.post(ApiURIs.DrAppointmentAppUnApp, {
  //     appointmentId: appId,
  //     isActive: isApp
  //   });
  // },
  approvedUnApproved(appointment: IDoctorAppointment) {
    return AuthAxios.post(ApiURIs.DrAppointmentAppUnApp, JSON.stringify(appointment));
  },
  GetAppoitmentByCurrentUser() {
    return AuthAxios.get(ApiURIs.DrAppointmentGetListByUser);
  },
  GetAppoitmentByDrId() {
    return AuthAxios.get(ApiURIs.DrAppointmentGetListByDrId);
  },
  GetAppoitmentByDate1() {
    return AuthAxios.get(ApiURIs.DrAppointmentGetListByAppointmentDate);
  },
  GetAppoitmentByDate(fromDate: Date, toDate: Date) {
    return AuthAxios.get(
      `${ApiURIs.DrAppointmentGetByAppointmentDate}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
    );
  },
  GetAppoitmentByCurrentDoctor() {
    return AuthAxios.get(ApiURIs.DrAppointmentGetListByDoctor);
  },
  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrAwardDelete}/${id}`);
  },
  cancel(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrAppointmentCancel}/${id}`);
  },

  daysOfMonth(month: number) {
    return AuthAxios.get(`${ApiURIs.DrAppointmentDaysOfMonth}?month=${month}`);
  },
  getChartDate(month: number) {
    return AuthAxios.get(`${ApiURIs.DrAppointmentChartData}?month=${month}`);
  },

  getByAppointmentDate(appointmentDate: Date) {
    return AuthAxios.get(`${ApiURIs.DrAppointmentGetByAppointmentDate}?appointmentDate=${appointmentDate.toISOString()}`);
  },

  getChamberCountByAppointmentDate(appointmentDate: Date) {
    return AuthAxios.get(`${ApiURIs.DrAppointmentGetChamberCountByAppointmentDate}?appointmentDate=${appointmentDate.toISOString()}`);
  },
  getChamberWeeklyCountByAppointmentDate(appointmentDate: Date) {
    return AuthAxios.get(
      `${ApiURIs.DrAppointmentGetChamberWeeklyCountByAppointmentDate}?appointmentDate=${appointmentDate.toISOString()}`
    );
  },
  getAppointmentSearch(phone: string, patientId: string, patientName: string, dob: Date) {
    return AuthAxios.get(
      `${ApiURIs.DrAppointmentSearch}?phone=${phone}&patientId=${patientId}&patientName=${patientName}&dob=${dob.toISOString()}`
    );
  },
  getAppointmentById(id: number) {
    return AuthAxios.get(`${ApiURIs.DrAppointmentById}?id=${id}`);
  }
};
