




















































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import AuthUser from "../../../global-config/AuthUser";
import AuthService from "../../../website/services/AuthService";
import DoctorService from "../../service/DoctorService";
import DoctorAppointmentService from "../../../doctor-app/service/DoctorAppointmentService";
import DoctorDetailService from "../../../doctor-app/service/DoctorDetailService";
import { IDoctorAppointment } from "../../../doctor-app/models/DoctorAppointment";
import { IDistrict } from "@/common-app/models/District";
import { IThana } from "@/common-app/models/Thana";
import VerifyEmail from "../../components/VerifyEmail.vue";
import UserService from "../../service/UserService";
import UniversalService from "@/common-app/service/UniversalService";
import { IUser } from "@/common-app/models/User";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  components: {
    ProfileWidget,
    VerifyEmail,
  },
})
export default class Home extends Vue {
  public isDoctorRequest: boolean = false;
  public appList: IDoctorAppointment[] = [];
  public appointmentList: IDoctorAppointment[] = [];
  public fee: number = 0;
  public title: string = "";
  public verifyEmailModal: boolean = AuthUser.IsEmailLogin();
  public user = AuthUser.getAuthUser();
  public address: string = AuthUser.getAddress();
  public district = "";
  public thana = "";
  public districts: IDistrict[] = [];
  public thanas: IThana[] = [];
  public userDetail: IUser = {} as IUser;
  public currentPage = 1;
  public perPage = 10;
  public rows = 10;
  public currentDate: Date = new Date();
  created() {
    this.isDoctorRequest =
      this.$store.getters.isDoctorRequest || AuthUser.getIsDoctorRequest();
    DoctorAppointmentService.GetAppoitmentByCurrentUser().then((res) => {
      this.appList = res.data.result || [];
      this.rows=this.appList.length;
      this.paginate(this.perPage, 0);
    });

    DoctorDetailService.getCurrentDoctorDetail().then((res: any) => {
      let user: any = res.data.result;
      if (user) {
        this.fee = user.visit;
        this.title = user.title;
      }
    });

    UserService.GetAddress().then((res) => {
      this.address = res.data.result.address;
      this.userDetail.address = res.data.result.address;
      this.userDetail.districtId = res.data.result.districtId;
      this.userDetail.thanaId = res.data.result.thanaId;
    });
    UniversalService.GetAddress().then((res) => {
      this.districts = res.data.districts;
      this.thanas = res.data.thanas;
      this.district =
        this.districts.find((s) => s.id == this.userDetail.districtId)!.name || "";
      this.thana = this.thanas.find((s) => s.id == this.userDetail.thanaId)!.name || "";
    });
  }

  doctorYes() {
    DoctorService.areYouDoctor().then((d) => {
      if (d.data.result) {
        this.$store.dispatch("addDoctorRequest", true);
        let user = AuthUser.getAuthUser();
        user.isDoctorRequest = true;
        AuthUser.setToken(user);
        this.$bvModal.hide("are-you-doctor-modal");
        this.$router.push({ path: "doctor/dashboard" });
      } else {
        alert(d.data.message);
      }
    });
  }

  verifyEmailClosed() {
    this.verifyEmailModal = false;
  }
  //   cancel(id: number) {
  //   if (confirm("Do you really want to cancle?")) {
  //     DoctorAppointmentService.cancel(id).then((res) => {
  //       if (res.data.status) {
  //         this.appList = this.appList.filter((s) => s.id !== id);
  //         // alert("cancel success");
  //         AlertService.sendMessage("cancel success");
  //       }
  //     });
  //   }
  // }


  paginate(page_size: number, page_number: number) {
    this.appointmentList = [];
    this.appointmentList = this.appList.slice(page_number * page_size, (page_number + 1) * page_size);
  }
  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }

  cancel(id: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to cancel?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorAppointmentService.cancel(id).then((res) => {
            if (res.data.status) {
              this.appList = this.appList.filter((s) => s.id !== id);
              // alert("cancel success");
              AlertService.sendMessage({ status: true, message: "Appointment Canceled" });
            }
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

    isSameDay(apptDate: Date) {
    apptDate = new Date(apptDate);
    if (this.currentDate <= apptDate) {
      return true;
    }
    // if(this.currentDate.getFullYear() === date2.getFullYear() &&
    //   this.currentDate.getMonth() === date2.getMonth() &&
    //   this.currentDate.getDay() === date2.getDay())
    //   {
    //      return true
    //   }
    return false;
  }
}
