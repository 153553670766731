import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";

export default {
  areYouDoctor() {
    return AuthAxios.get("user/AreYouDoctor");
  },

  ReSendCode(data: any) {
    return AuthAxios.post(ApiURIs.reSendCode, JSON.stringify(data));
  },

  test() {
    return AuthAxios.get("api/user/test");
  }
};
